<template>
    <div>
        <component :is="imgObj" :id="route.params.logoId" />
    </div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import ImgLogo from '@/components/ImgLogo.vue'

export default {
    name: 'Logo',
    setup () {
        const axios = inject('axios')
        const route = useRoute()

        const imgObj = computed(() => {
            return ImgLogo
        })

        return { 
            route, ImgLogo, imgObj
        }
    }
}
</script>

<style>

</style>